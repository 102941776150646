
export default {
  name: 'Author',
  props: {
    author: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
}
